import React from 'react'
import Sidebar from '../components/Sidebar/Sidebar'
import Header from '../components/Header/Header'


const CommonLayout = ({ children }) => {
    return (
        <>
           <div className="mainadminpanel">
          <Sidebar />
          <div className="contentbar">
            <Header />
            {children}
          </div>
        </div>
        </>
    )
}

export default CommonLayout