import React from 'react'
import { Button, FormControl } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function Settings() {
  return (
    <>
    <div className="innercontent">
                <div className="heading_sec">
                    <h4>Settings</h4>
                    <div className="rightheadinner">
                        <div className="searchform">
                                <FormControl type="text" placeholder="Search" />
                                <Button variant="outline-secondary">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <circle cx="11.058" cy="11.058" r="7.06194" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M20.0024 20.0063L16.0508 16.0547" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </Button>
                        </div>
                        <div className="filter">
                             <Link to="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M6.76776 4.06426C7.74407 5.04057 7.74407 6.62349 6.76776 7.5998C5.79146 8.5761 4.20854 8.5761 3.23223 7.5998C2.25592 6.62349 2.25592 5.04057 3.23223 4.06426C4.20854 3.08795 5.79146 3.08795 6.76776 4.06426" stroke="#1B1B1B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16.6667 5.83073H7.5" stroke="#1B1B1B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16.7678 12.3963C17.7441 13.3726 17.7441 14.9555 16.7678 15.9318C15.7915 16.9081 14.2085 16.9081 13.2322 15.9318C12.2559 14.9555 12.2559 13.3726 13.2322 12.3963C14.2085 11.42 15.7915 11.42 16.7678 12.3963" stroke="#1B1B1B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M3.33398 14.1667H12.5007" stroke="#1B1B1B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                Filter
                            </Link>
                        </div>

                    </div>
                </div>
                </div>
    </>
  )
}

export default Settings