import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { handleLogout } from "../../utils/common";
import "./Header.scss";
import clientimg from "../../assets/imgs/client.png";

function Header() {
  const currentPath = window.location.pathname;

  let headerHeading = "";

  if (currentPath === "/userManagement") {
    headerHeading = "User Management";
  } else if (currentPath === "/dataManagement") {
    headerHeading = "Data Management";
  } else if (currentPath === "/analytics") {
    headerHeading = "Analytics";
  } else if (currentPath === "/settings") {
    headerHeading = "Settings";
  }

  const email = sessionStorage.getItem("email");
  const handleLogoutButton = () => {
    handleLogout(email, false);
  };

  return (
    <>
      <div className="headeradmin">
        <div className="name">{headerHeading}</div>
        <div className="head_right">
          <ul>
            <li>
              <Link to="" className="notification">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.6686 11.5752C12.3669 11.5752 12.9335 11.0103 12.9358 10.312V10.312V10.312C12.9353 9.95672 12.7935 9.6162 12.5416 9.36563L11.7019 8.52528V6.02891C11.7017 5.04768 11.3115 4.10676 10.6173 3.41337C9.923 2.71997 8.98159 2.33097 8.00037 2.33203V2.33203C5.95931 2.33314 4.30519 3.98786 4.30483 6.02891V8.52328L3.46514 9.36363C3.21306 9.61414 3.071 9.95464 3.07031 10.31V10.31V10.31C3.07252 11.0083 3.63921 11.5732 4.33751 11.5732L11.6686 11.5752Z"
                    stroke="#0A0D12"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.01367 13.6694H8.98583"
                    stroke="#0A0D12"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
            </li>
            <li>
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  <img src={clientimg} alt="avtar" />
                  {email}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleLogoutButton}>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Header;
