export const API_BASE_URL = "https://api.teltonika.lt/";
export const API_BEARER_TOKEN = "3654|gFA06uU2DBAgEa6xwehJDlkSbLrDt7pcC7VhDBQ7";
export const AUTH0_CLIENT_ID = "1gfMPPbgGTuqbT3fOY46d4ZehXtWtngS";

export const AUTH0_CLIENT_SECRET =
  "0_zX0xzOsTnI58ZzNMuuYQYbxl9Nx0KeN8HqjQ0dKopS3c7v6xFK2955YUTNFBfq";

export const AUTH0_DOMAIN = "auth.novotrack.cloud";

export const AUTH0_USER_SCOPE =
  "openid profile user_metadata  email read:users read:current_user update:users update:current_user_identities update:users_app_metadata,update:current_user_metadata offline_access update:users update:users_app_metadata update:current_user_metadata update:current_user_identities";

export const AUTH0_LOGIN_RESPONSE_TYPE = "code";

export const AUTH0_LOGOUT_URL = "https://novotrack.cloud";

export const AUTH0_REDIRECT_URL = "https://novotrack.cloud/authorize";

export const AUTH0_CODE_CHALLENGE_METHOD = "S256";

export const AUTH0_REALM = "Username-Password-Authentication";

export const AUTH0_GRANT_TYPE = "authorization_code";

export const AUTH0_BASE_URL = "https://novotrack.cloud";
