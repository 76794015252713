import { auth } from "../services/auth0.service";
import { AUTH0_CLIENT_ID,AUTH0_BASE_URL } from "../constants/auth0";

export const handleLogout = (email, data) => {
  sessionStorage.clear();
  auth.logout({
    returnTo: AUTH0_BASE_URL,
    clientID: AUTH0_CLIENT_ID,
    federated: true,
  });
};
