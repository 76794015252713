import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../../services/auth0.service";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import {
  AUTH0_REDIRECT_URL,
  AUTH0_GRANT_TYPE,
  AUTH0_USER_SCOPE,
  AUTH0_CLIENT_SECRET
} from "../../../constants/auth0";
const Authorize = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [profile, setProfile] = useState();


  const processAuthentication = async (search) => {
    setLoading(true);
    const params = new URLSearchParams(search);
    
    const code = params.get("code");

    const code_verifier = sessionStorage.getItem("verifier");
    // console.log("token code_verifier is here", code_verifier);
    // this is the api for getting the access token and refresh token
    await auth.client.oauthToken(
      {
        code,
        code_verifier,
        grantType: AUTH0_GRANT_TYPE,
        scope:AUTH0_USER_SCOPE,
        redirect_uri: AUTH0_REDIRECT_URL,
        client_secret: AUTH0_CLIENT_SECRET,
      },
      (error, result) => {
        if (error) {
          toast.error(error?.error);
          setLoading(false);
          navigate("/");
        }
        if (result) {
          const { accessToken, idToken } = result;
          sessionStorage.setItem("accessToken", accessToken);
          sessionStorage.setItem("idToken", idToken);
          accessToken &&
          auth.client.userInfo(accessToken, async (error, result) => {
            if (error) {
              setLoading(false);
              return;
            }
              setProfile(result);
              setLoading(true);
              sessionStorage.setItem("email", result?.email);
              sessionStorage.setItem("authUserId", result?.sub);
              sessionStorage.setItem("isAuthenticated", true);
             
            const redirectUrl = location?.state?.path || "/userManagement";
            navigate(redirectUrl, { replace: true });
            });
        }
      }
    );
  };
  useEffect(() => {
    if (location.search) {
      processAuthentication(location.search);
    }
    // eslint-disable-next-line
  }, [location.search]);
  return (
    <>
      {" "}
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <div className="main-section"></div>
      )}
    </>
  );
};
export default Authorize;
