export const DownArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
        <path d="M0.646447 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L7 6.29289L12.6464 0.646447C12.8417 0.451184 13.1583 0.451184 13.3536 0.646447C13.5488 0.841709 13.5488 1.15829 13.3536 1.35355L7.35355 7.35355C7.15829 7.54882 6.84171 7.54882 6.64645 7.35355L0.646447 1.35355C0.451184 1.15829 0.451184 0.841709 0.646447 0.646447Z" fill="" />
    </svg>
)

export const Hamburger = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none">
        <path d="M0.752441 1.00342C0.752441 0.589204 1.08823 0.253418 1.50244 0.253418H14.5075C14.9217 0.253418 15.2575 0.589204 15.2575 1.00342C15.2575 1.41763 14.9217 1.75342 14.5075 1.75342H1.50244C1.08823 1.75342 0.752441 1.41763 0.752441 1.00342ZM0.752781 5.00508C0.752781 4.59087 1.08857 4.25508 1.50278 4.25508H14.5079C14.9221 4.25508 15.2579 4.59087 15.2579 5.00508C15.2579 5.4193 14.9221 5.75508 14.5079 5.75508H1.50278C1.08857 5.75508 0.752781 5.4193 0.752781 5.00508ZM0.752781 9.00675C0.752781 8.59254 1.08857 8.25675 1.50278 8.25675H14.5079C14.9221 8.25675 15.2579 8.59254 15.2579 9.00675C15.2579 9.42096 14.9221 9.75675 14.5079 9.75675H1.50278C1.08857 9.75675 0.752781 9.42096 0.752781 9.00675Z" fill="#0A0D12" />
    </svg>
)

export const TickMark = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" fill="none">
        <path d="M17.5303 0.46967C17.8232 0.762563 17.8232 1.23744 17.5303 1.53033L6.53033 12.5303C6.23744 12.8232 5.76256 12.8232 5.46967 12.5303L0.46967 7.53033C0.176777 7.23744 0.176777 6.76256 0.46967 6.46967C0.762563 6.17678 1.23744 6.17678 1.53033 6.46967L6 10.9393L16.4697 0.46967C16.7626 0.176777 17.2374 0.176777 17.5303 0.46967Z" fill="#0068FF" />
    </svg>
)
export const ArrowRight = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
        <path d="M10.4697 0.46967C10.7626 0.176777 11.2374 0.176777 11.5303 0.46967L17.5303 6.46967C17.8232 6.76256 17.8232 7.23744 17.5303 7.53033L11.5303 13.5303C11.2374 13.8232 10.7626 13.8232 10.4697 13.5303C10.1768 13.2374 10.1768 12.7626 10.4697 12.4697L15.1893 7.75H1C0.585786 7.75 0.25 7.41421 0.25 7C0.25 6.58579 0.585786 6.25 1 6.25H15.1893L10.4697 1.53033C10.1768 1.23744 10.1768 0.762563 10.4697 0.46967Z" fill="" />
    </svg>
)

export const LocationIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M11.75 7.5C10.5072 7.5 9.5 8.50721 9.5 9.75C9.5 10.9928 10.5072 12 11.75 12C12.9928 12 14 10.9928 14 9.75C14 8.50721 12.9928 7.5 11.75 7.5ZM8 9.75C8 7.67879 9.67879 6 11.75 6C13.8212 6 15.5 7.67879 15.5 9.75C15.5 11.8212 13.8212 13.5 11.75 13.5C9.67879 13.5 8 11.8212 8 9.75Z" fill="" />
        <path d="M11.75 3.5C8.29821 3.5 5.5 6.29821 5.5 9.75C5.5 12.0884 7.08713 14.658 8.82546 16.7378C9.67687 17.7564 10.5304 18.6178 11.1718 19.2248C11.3934 19.4346 11.5892 19.6134 11.75 19.7573C11.9108 19.6134 12.1066 19.4346 12.3282 19.2248C12.9696 18.6178 13.8231 17.7564 14.6745 16.7378C16.4129 14.658 18 12.0884 18 9.75C18 6.29821 15.2018 3.5 11.75 3.5ZM11.75 20.75C11.2739 21.3295 11.2736 21.3293 11.2736 21.3293L11.2717 21.3277L11.2668 21.3237L11.2497 21.3095C11.2351 21.2972 11.214 21.2796 11.1868 21.2566C11.1326 21.2107 11.0543 21.1437 10.9553 21.0571C10.7575 20.884 10.4767 20.6323 10.1407 20.3143C9.46961 19.6791 8.57313 18.7748 7.67454 17.6997C5.91287 15.592 4 12.6616 4 9.75C4 5.46979 7.46979 2 11.75 2C16.0302 2 19.5 5.46979 19.5 9.75C19.5 12.6616 17.5871 15.592 15.8255 17.6997C14.9269 18.7748 14.0304 19.6791 13.3593 20.3143C13.0233 20.6323 12.7425 20.884 12.5447 21.0571C12.4457 21.1437 12.3674 21.2107 12.3132 21.2566C12.286 21.2796 12.2649 21.2972 12.2503 21.3095L12.2332 21.3237L12.2283 21.3277L12.2269 21.3289C12.2269 21.3289 12.2261 21.3295 11.75 20.75ZM11.75 20.75L12.2261 21.3295C11.9494 21.5568 11.5503 21.5565 11.2736 21.3293L11.75 20.75Z" fill="" />
    </svg>
)

export const CallIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M5.36565 3.90901C5.7876 3.48705 6.3599 3.25 6.95664 3.25C7.55338 3.25 8.12568 3.48706 8.54764 3.90902L10.465 5.82636C11.5389 6.9003 11.5389 8.64148 10.465 9.71542L9.3143 10.8661C10.0731 12.5661 11.4338 13.9269 13.1339 14.6857L14.2846 13.535C14.8003 13.0193 15.4998 12.7296 16.2291 12.7296C16.9584 12.7296 17.6579 13.0193 18.1736 13.535L20.091 15.4524C20.9697 16.331 20.9697 17.7557 20.091 18.6343L19.2324 19.4929C18.4284 20.2969 17.3383 20.749 16.2013 20.75C12.7655 20.753 9.46952 19.3894 7.04004 16.9599C4.61057 14.5304 3.24702 11.2345 3.25 7.79869C3.25099 6.66169 3.70311 5.57153 4.5071 4.76755L5.36565 3.90901V3.90901ZM6.95664 4.75C6.75773 4.75 6.56696 4.82902 6.42631 4.96967L5.56775 5.82822C5.04475 6.35121 4.75065 7.06037 4.75 7.8C4.74737 10.8375 5.95285 13.7514 8.10071 15.8993C10.2486 18.0471 13.1624 19.2526 16.2 19.25C16.9396 19.2493 17.6488 18.9552 18.1718 18.4322L19.0303 17.5737C19.3232 17.2808 19.3232 16.8059 19.0303 16.513L17.113 14.5957C16.8786 14.3613 16.5606 14.2296 16.2291 14.2296C15.8976 14.2296 15.5796 14.3613 15.3452 14.5957L13.8421 16.0988C13.6345 16.3064 13.3248 16.3738 13.0497 16.2712C10.5883 15.3532 8.64679 13.4117 7.72883 10.9503C7.62624 10.6752 7.69361 10.3655 7.90122 10.1579L9.40429 8.65477C9.89244 8.16662 9.89244 7.37517 9.40429 6.88701L7.48697 4.96967C7.34632 4.82902 7.15555 4.75 6.95664 4.75Z" fill="" />
    </svg>
)

export const EmailIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M2.015 7.301C2.015 5.47779 3.49279 4 5.316 4H18.199C20.0224 4 21.5 5.47798 21.5 7.302C21.5 8.39614 20.9582 9.41991 20.0509 10.0338M20.0509 10.0338L15.5449 13.0888C13.2578 14.639 10.2573 14.6391 7.97019 13.0888L3.46429 10.0339C3.46423 10.0338 3.46434 10.0339 3.46429 10.0339C2.55729 9.41923 2.015 8.39552 2.015 7.301M14.7032 11.8472C14.7032 11.8472 14.7032 11.8472 14.7032 11.8472L19.2091 8.79222C19.7034 8.45803 20 7.89955 20 7.302C20 6.30602 19.1936 5.5 18.199 5.5H5.316C4.32121 5.5 3.515 6.30621 3.515 7.301C3.515 7.89842 3.8108 8.45676 4.30571 8.79211L8.8118 11.8472C8.81183 11.8472 8.81178 11.8472 8.8118 11.8472C10.5907 13.0529 12.9243 13.0529 14.7032 11.8472Z" fill="" />
        <path d="M2.75 6.551C3.16421 6.551 3.5 6.88679 3.5 7.301V16.75C3.5 17.9928 4.50721 19 5.75 19H17.75C18.9928 19 20 17.9928 20 16.75V7.302C20 6.88779 20.3358 6.552 20.75 6.552C21.1642 6.552 21.5 6.88779 21.5 7.302V16.75C21.5 18.8212 19.8212 20.5 17.75 20.5H5.75C3.67879 20.5 2 18.8212 2 16.75V7.301C2 6.88679 2.33579 6.551 2.75 6.551Z" fill="" />
    </svg>
)

export const EyeIcon = () => (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5912 6.4101C10.7124 5.5305 9.28761 5.53009 8.40735 6.41009C7.52865 7.29052 7.52933 8.71673 8.40698 9.59453C9.28747 10.4732 10.7137 10.4724 11.5915 9.59466C12.47 8.71417 12.4689 7.2878 11.5912 6.4101ZM12.6513 5.34881C11.1861 3.88357 8.81186 3.88426 7.34646 5.34966C5.88341 6.8152 5.88231 9.19118 7.34646 10.6553C8.81199 12.1184 11.188 12.1195 12.6521 10.6553C14.1151 9.18986 14.1163 6.81405 12.6523 5.34989" fill="#0A0D12" />
        <path d="M10 1.75C6.56983 1.75 3.49798 3.60008 2.11971 6.41757C1.87516 6.91749 1.75 7.45692 1.75 8C1.75 8.54308 1.87516 9.08251 2.11971 9.58243C3.49798 12.3999 6.56983 14.25 10 14.25C13.4302 14.25 16.502 12.3999 17.8803 9.58243C18.1248 9.08251 18.25 8.54308 18.25 8C18.25 7.45692 18.1248 6.91749 17.8803 6.41757C16.502 3.60008 13.4302 1.75 10 1.75ZM0.77229 5.75843C2.42402 2.38192 6.04817 0.25 10 0.25C13.9518 0.25 17.576 2.38192 19.2277 5.75843C19.5712 6.46051 19.75 7.22508 19.75 8C19.75 8.77492 19.5712 9.53949 19.2277 10.2416C17.576 13.6181 13.9518 15.75 10 15.75C6.04817 15.75 2.42402 13.6181 0.77229 10.2416C0.428843 9.53949 0.25 8.77492 0.25 8C0.25 7.22508 0.428843 6.46051 0.77229 5.75843Z" fill="#0A0D12" />
    </svg>

)

export const BackArrowIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
        <path d="M5.02883 0.638361C5.2241 0.833606 5.22413 1.15019 5.02888 1.34547L1.8883 4.48657H10.6665C10.9427 4.48657 11.1665 4.71043 11.1665 4.98657C11.1665 5.26271 10.9427 5.48657 10.6665 5.48657H1.86156L5.02888 8.65442C5.22413 8.8497 5.2241 9.16628 5.02883 9.36153C4.83355 9.55677 4.51697 9.55675 4.32172 9.36147L0.314385 5.35347C0.168016 5.20707 0.131388 4.9925 0.204502 4.81199C0.238822 4.71989 0.299511 4.64064 0.377389 4.58341L4.32172 0.638419C4.51697 0.443141 4.83355 0.443115 5.02883 0.638361Z" fill="" />
    </svg>
)

export const VisaCardIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="8" viewBox="0 0 25 8" fill="none">
        <path d="M6.12526 7.75822H4.00494L2.41495 1.69235C2.33949 1.41331 2.17925 1.16664 1.94354 1.05037C1.35531 0.758201 0.707118 0.525675 0 0.408402V0.174866H3.41567C3.88708 0.174866 4.24064 0.525675 4.29957 0.9331L5.12454 5.30862L7.24383 0.174866H9.30522L6.12526 7.75822ZM10.4839 7.75822H8.48145L10.1304 0.174866H12.1328L10.4839 7.75822ZM14.7236 2.27571C14.7825 1.86728 15.136 1.63374 15.5485 1.63374C16.1967 1.57511 16.9028 1.69238 17.4921 1.98354L17.8456 0.35081C17.2564 0.117274 16.6082 0 16.0199 0C14.0764 0 12.6622 1.05041 12.6622 2.50824C12.6622 3.61728 13.6639 4.19961 14.371 4.55042C15.136 4.90021 15.4307 5.13375 15.3717 5.48355C15.3717 6.00825 14.7825 6.24178 14.1943 6.24178C13.4871 6.24178 12.78 6.06688 12.1329 5.77471L11.7793 7.40845C12.4864 7.69962 13.2514 7.81689 13.9585 7.81689C16.1378 7.87451 17.4921 6.82512 17.4921 5.25001C17.4921 3.26647 14.7236 3.15021 14.7236 2.27571ZM24.4997 7.75822L22.9097 0.174866H21.2018C20.8483 0.174866 20.4947 0.408402 20.3769 0.758201L17.4326 7.75822H19.494L19.9055 6.65019H22.4383L22.674 7.75822H24.4997ZM21.4972 2.2171L22.0854 5.07514H20.4365L21.4972 2.2171Z" fill="#172B85" />
    </svg>
)

export const MasterCardIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="14" viewBox="0 0 23 14" fill="none">
        <path d="M11.75 12.254C10.5585 13.2823 9.01278 13.903 7.32377 13.903C3.55511 13.903 0.5 10.8124 0.5 7.00003C0.5 3.18762 3.55511 0.0970459 7.32377 0.0970459C9.01278 0.0970459 10.5585 0.717811 11.75 1.74611C12.9416 0.717811 14.4873 0.0970459 16.1763 0.0970459C19.945 0.0970459 23.0001 3.18762 23.0001 7.00003C23.0001 10.8124 19.945 13.903 16.1763 13.903C14.4873 13.903 12.9416 13.2823 11.75 12.254Z" fill="#ED0006" />
        <path d="M16.1763 13.903C19.945 13.903 23.0001 10.8124 23.0001 7.00003C23.0001 3.18762 19.945 0.0970459 16.1763 0.0970459C14.4873 0.0970459 12.9416 0.717817 11.75 1.74613C13.2172 3.01226 14.1475 4.89626 14.1475 7.00006C14.1475 9.10385 13.2172 10.9878 11.75 12.254C12.9416 13.2823 14.4873 13.903 16.1763 13.903Z" fill="#F9A000" />
        <path d="M11.751 12.2539C13.2182 10.9878 14.1485 9.10381 14.1485 7.00001C14.1485 4.89621 13.2182 3.01223 11.751 1.74609C10.2838 3.01223 9.35352 4.89621 9.35352 7.00001C9.35352 9.10381 10.2838 10.9878 11.751 12.2539Z" fill="#FF5E00" />
    </svg>
)

export const CarIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
        <path d="M48.007 37.3359C53.9005 37.3359 58.6782 42.1136 58.6782 48.007C58.6782 53.9005 53.9005 58.6782 48.007 58.6782C42.1136 58.6782 37.3359 53.9005 37.3359 48.007C37.3359 42.1136 42.1136 37.3359 48.007 37.3359" stroke="#8AB9FF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M51.604 46.2109L47.1114 50.7168L44.4116 48.0064" stroke="#0068FF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M22.1933 30.5893H16.543" stroke="#0068FF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M44.9472 30.5893H39.2969" stroke="#0068FF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M47.772 22.1128L53.4197 19.2876" stroke="#0068FF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.7182 22.1128L8.06787 19.2876" stroke="#0068FF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21.5531 7.99072C18.9276 7.99099 16.6479 9.79886 16.0494 12.3552L13.6484 22.5995L16.0494 12.3552C16.6479 9.79886 18.9276 7.99099 21.5531 7.99072H39.9367C42.5619 7.99027 44.8412 9.79871 45.4377 12.3552L47.8387 22.5995" stroke="#0068FF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M53.4201 31.9998V29.8923C53.4201 25.7224 50.0428 22.3404 45.873 22.3345H15.6284C13.6234 22.3338 11.7004 23.1297 10.2824 24.5472C8.8645 25.9646 8.06787 27.8874 8.06787 29.8923V42.6709C8.06787 44.1443 9.26228 45.3387 10.7356 45.3387H16.7008C18.1742 45.3387 19.3686 44.1443 19.3686 42.6709V39.0614H32.0005" stroke="#0068FF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export const NavigationIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
        <circle cx="49.3332" cy="50.6667" r="6.66667" stroke="#8AB9FF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.3346 14.3335C17.5185 14.3342 17.6671 14.4838 17.6667 14.6677C17.6662 14.8516 17.5168 15.0004 17.3329 15.0002C17.149 14.9999 17 14.8508 17 14.6668C16.9995 14.5781 17.0347 14.4928 17.0976 14.4301C17.1604 14.3675 17.2458 14.3327 17.3346 14.3335" stroke="#8AB9FF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M26.6667 14.6668C26.6667 19.0972 21.716 23.8355 19.0238 26.0842C18.0293 26.8614 16.6331 26.8611 15.6391 26.0833C12.9493 23.8344 8 19.0966 8 14.6668V14.6668C8 9.51217 12.1787 5.3335 17.3333 5.3335V5.3335C22.488 5.3335 26.6667 9.51217 26.6667 14.6668V14.6668Z" stroke="#0068FF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M32 50.6665H24C19.5817 50.6665 16 47.0848 16 42.6665V42.6665C16 38.2482 19.5817 34.6665 24 34.6665H40C44.4183 34.6665 48 31.0848 48 26.6665V26.6665C48 22.2482 44.4183 18.6665 40 18.6665H34.6667" stroke="#0068FF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)