import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import PrivateAuth from "./components/Auth/Authorize/CheckAuth";

import Login from "./components/Auth/Login/Login";
import Authorize from "./components/Auth/Authorize/Authorize";

import SignUp from "./components/Auth/SignUp/SignUp";
import PublicRoutes from "./routes/PublicRoutes";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route exact path="/authorize" element={<Authorize />} />
          <Route element={<PrivateAuth />}>
          {PublicRoutes.map((route, idx) => {
            return (
              <Route
                exact={route.exact ? true : false}
                key={idx}
                path={route.path}
                element={<route.layout>{route.component}</route.layout>}
              />
            );
          })}
          </Route>          
        </Routes>
      </Router>
    </> 
  );
}
export default App;
