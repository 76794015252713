import React from 'react'

const AuthLayout = ({ children }) => {
    return (
        <>
            <div className='main-wrapper'>
                {children}
            </div>
        </>
    )
}

export default AuthLayout