import { React } from "react";
import AuthLayout from "../layouts/Auth";
import CommonLayout from "../layouts/Common";
import Login from "../components/Auth/Login/Login";
import Authorize from "../components/Auth/Authorize/Authorize";

import SignUp from "../components/Auth/SignUp/SignUp";
import User from "../containers/User/UserManagement";
import Data from "../containers/Data/DataManagement";
import Analytics from "../containers/Analytics/Analytics";
import Setting from "../containers/Setting/Setting";

const PublicRoutes = [
  {
    path: "/",
    exact: true,
    layout: AuthLayout,
    component: <Login />,
  },
  {
    path: "/login",
    exact: true,
    layout: AuthLayout,
    component: <Login />,
  },
  {
    path: "/authorize",
    exact: true,
    layout: CommonLayout,
    component: <Authorize />,
  },
  {
    path: "/signup",
    exact: true,
    layout: AuthLayout,
    component: <SignUp />,
  },
  {
    path: "/userManagement",
    exact: true,
    layout: CommonLayout,
    component: <User />,
  },
  {
    path: "/dataManagement",
    exact: true,
    layout: CommonLayout,
    component: <Data />,
  },
  {
    path: "/analytics",
    exact: true,
    layout: CommonLayout,
    component: <Analytics />,
  },
  {
    path: "/settings",
    exact: true,
    layout: CommonLayout,
    component: <Setting />,
  },
];

export default PublicRoutes;
