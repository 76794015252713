import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './Sidebar.scss'

function Sidebar() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <>
            <div className={`sidebaradmin ${isSidebarOpen ? 'open' : 'closed'}`}>
                {/* <button onClick={toggleSidebar} className="toggle-button" >
            <svg className='close' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
            <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
            </svg>
            <svg className='open' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
            <path d="M 0 9 L 0 11 L 50 11 L 50 9 Z M 0 24 L 0 26 L 50 26 L 50 24 Z M 0 39 L 0 41 L 50 41 L 50 39 Z"></path>
            </svg>
            </button> */}
                <h2>
                    <svg className='open' xmlns="http://www.w3.org/2000/svg" width="203" height="48" viewBox="0 0 203 48" fill="none">
                        <g clipPath="url(#clip0_6768_29830)">
                            <path
                                d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
                                fill="#0068FF" />
                            <path
                                d="M56.3828 31.8445V16.6714H63.5288V18.7698C64.1655 17.8989 64.8684 17.252 65.6426 16.8242C66.4168 16.3963 67.2877 16.1875 68.2555 16.1875C69.8701 16.1875 71.1485 16.6765 72.0908 17.6544C73.0331 18.6323 73.5068 19.9668 73.5068 21.6578V31.8395H66.1417V24.179C66.1417 23.4252 66.0653 22.9057 65.9074 22.6255C65.7495 22.3403 65.4745 22.1977 65.0874 22.1977C64.6545 22.1977 64.3285 22.3658 64.1044 22.6968C63.8803 23.0279 63.7682 23.522 63.7682 24.179V31.8395H56.3879L56.3828 31.8445Z"
                                fill="#0068FF" />
                            <path
                                d="M93.6113 24.3013C93.6113 25.4473 93.3669 26.5372 92.8779 27.5712C92.3889 28.6103 91.6911 29.522 90.7794 30.3165C89.9848 30.999 89.0782 31.5237 88.0595 31.8802C87.0358 32.2367 85.9356 32.415 84.7489 32.415C83.5621 32.415 82.467 32.2367 81.4483 31.8853C80.4297 31.5338 79.518 31.0092 78.703 30.3165C77.8015 29.5525 77.1037 28.6459 76.6096 27.5967C76.1156 26.5474 75.8711 25.4473 75.8711 24.3013C75.8711 23.1552 76.1156 22.05 76.6096 21.0058C77.1037 19.9668 77.8015 19.0551 78.703 18.286C79.5129 17.5933 80.4297 17.0687 81.4483 16.7172C82.467 16.3658 83.5672 16.1875 84.7489 16.1875C85.9305 16.1875 87.0358 16.3658 88.0595 16.7223C89.0833 17.0788 90.0001 17.5984 90.81 18.286C91.7013 19.05 92.3889 19.9515 92.8779 21.0007C93.3669 22.05 93.6113 23.1501 93.6113 24.3013ZM84.7489 26.3692C85.2786 26.3692 85.7064 26.1807 86.0426 25.7987C86.3736 25.4167 86.5417 24.9175 86.5417 24.3013C86.5417 23.685 86.3787 23.1756 86.0528 22.7987C85.7268 22.4218 85.2938 22.2333 84.7539 22.2333C84.214 22.2333 83.7709 22.4218 83.4399 22.7987C83.1139 23.1756 82.9458 23.6748 82.9458 24.3013C82.9458 24.9277 83.1088 25.4269 83.4399 25.8038C83.7658 26.1807 84.2039 26.3692 84.7539 26.3692H84.7489Z"
                                fill="#0068FF" />
                            <path
                                d="M100.151 31.8462L93.6367 16.6731H101.445L102.942 21.9243C103.121 22.4999 103.248 22.9583 103.324 23.2945C103.401 23.6306 103.457 23.9311 103.487 24.196C103.538 23.7732 103.681 23.1417 103.925 22.286C103.976 22.1179 104.007 22.0007 104.027 21.9345L105.509 16.668H113.404L106.829 31.8411H100.151V31.8462Z"
                                fill="#0068FF" />
                            <path
                                d="M131.174 24.3013C131.174 25.4473 130.929 26.5372 130.44 27.5712C129.951 28.6103 129.254 29.522 128.342 30.3165C127.547 30.999 126.641 31.5237 125.622 31.8802C124.598 32.2367 123.498 32.415 122.311 32.415C121.125 32.415 120.03 32.2367 119.011 31.8853C117.992 31.5338 117.08 31.0092 116.266 30.3165C115.364 29.5525 114.666 28.6459 114.172 27.5967C113.678 26.5474 113.434 25.4473 113.434 24.3013C113.434 23.1552 113.678 22.05 114.172 21.0058C114.666 19.9668 115.364 19.0551 116.266 18.286C117.075 17.5933 117.992 17.0687 119.011 16.7172C120.03 16.3658 121.13 16.1875 122.311 16.1875C123.493 16.1875 124.598 16.3658 125.622 16.7223C126.646 17.0788 127.563 17.5984 128.372 18.286C129.264 19.05 129.951 19.9515 130.44 21.0007C130.929 22.05 131.174 23.1501 131.174 24.3013ZM122.311 26.3692C122.841 26.3692 123.269 26.1807 123.605 25.7987C123.936 25.4167 124.104 24.9175 124.104 24.3013C124.104 23.685 123.941 23.1756 123.615 22.7987C123.289 22.4218 122.856 22.2333 122.316 22.2333C121.777 22.2333 121.333 22.4218 121.002 22.7987C120.676 23.1756 120.508 23.6748 120.508 24.3013C120.508 24.9277 120.671 25.4269 121.002 25.8038C121.328 26.1807 121.766 26.3692 122.316 26.3692H122.311Z"
                                fill="#0068FF" />
                            <path
                                d="M134.623 31.8445V20.7206H132.611V17.4506H134.623V12.668H138.805V17.4506H140.817V20.7206H138.805V31.8445H134.623Z"
                                fill="#0068FF" />
                            <path
                                d="M147.432 21.1504C147.855 19.7497 148.456 18.7056 149.246 18.0129C150.035 17.3202 151.008 16.9688 152.174 16.9688C152.439 16.9688 152.872 17.0197 153.478 17.1165C153.595 17.1368 153.682 17.1521 153.743 17.1623L153.112 21.6547C152.796 21.5273 152.485 21.4305 152.164 21.3694C151.848 21.3083 151.512 21.2727 151.161 21.2727C149.887 21.2727 148.976 21.5986 148.415 22.2506C147.855 22.9025 147.58 23.9874 147.58 25.5154V31.8567H143.398V17.4628H147.524L147.437 21.1606L147.432 21.1504Z"
                                fill="#0068FF" />
                            <path
                                d="M170.056 31.8465H166.007L166.068 29.7786C165.503 30.6903 164.82 31.3575 164.02 31.7803C163.221 32.2081 162.253 32.417 161.107 32.417C159.197 32.417 157.659 31.709 156.492 30.2879C155.326 28.872 154.74 26.9976 154.74 24.6699C154.74 22.3423 155.316 20.3558 156.462 19.001C157.613 17.6462 159.207 16.9688 161.255 16.9688C162.319 16.9688 163.256 17.2081 164.066 17.6869C164.876 18.1657 165.548 18.8788 166.098 19.8312V17.4526H170.061V31.8465H170.056ZM166.241 24.6292C166.241 23.3355 165.915 22.3168 165.263 21.563C164.611 20.8092 163.735 20.4322 162.63 20.4322C161.525 20.4322 160.603 20.8041 159.951 21.5528C159.299 22.3015 158.973 23.3253 158.973 24.6292C158.973 25.9331 159.294 26.9416 159.935 27.6648C160.577 28.3881 161.463 28.7497 162.599 28.7497C163.735 28.7497 164.647 28.3881 165.284 27.6648C165.92 26.9416 166.236 25.928 166.236 24.6292H166.241Z"
                                fill="#0068FF" />
                            <path
                                d="M184.598 31.4159C184.032 31.7572 183.431 32.0119 182.81 32.1698C182.184 32.3328 181.501 32.4142 180.757 32.4142C178.486 32.4142 176.637 31.691 175.211 30.2496C173.785 28.8081 173.066 26.9287 173.066 24.6214C173.066 22.3141 173.785 20.5212 175.221 19.0951C176.657 17.674 178.521 16.9609 180.813 16.9609C181.577 16.9609 182.26 17.0322 182.866 17.18C183.472 17.3277 184.048 17.5569 184.598 17.8676L184.567 22.0645C184.129 21.6163 183.62 21.2699 183.039 21.0204C182.464 20.7759 181.852 20.6536 181.206 20.6536C180.034 20.6536 179.092 21.0153 178.389 21.7334C177.686 22.4516 177.335 23.4041 177.335 24.5857C177.335 25.7674 177.696 26.7657 178.414 27.4839C179.133 28.202 180.105 28.5637 181.328 28.5637C182.005 28.5637 182.601 28.4414 183.126 28.1969C183.65 27.9524 184.129 27.5704 184.572 27.0509L184.603 31.4109L184.598 31.4159Z"
                                fill="#0068FF" />
                            <path
                                d="M187.752 9.09766H191.934V23.8175L197.277 17.4508H202.294L196.497 23.8175L202.864 31.8447H197.699L191.934 24.2148V31.8447H187.752V9.09766Z"
                                fill="#0068FF" />
                            <path
                                d="M28.5486 24.1575C28.5639 25.8485 28.5181 25.2729 28.5181 26.9028C28.5181 30.9266 27.4587 28.7976 26.1853 26.4546C24.1581 22.7212 23.9697 22.385 21.2702 17.7704C19.7371 15.1524 17.3687 14.3578 14.9493 15.0251C11.1649 16.0641 11.44 20.6125 11.44 22.2424C11.44 26.3273 11.5673 37.6295 11.5673 37.6295C11.5673 37.6295 14.5826 37.5836 18.2244 37.5836C18.097 33.4274 18.0919 29.2407 18.0155 26.5208C17.9697 24.7993 18.5605 25.166 18.9171 25.8434C21.2855 30.351 22.1004 31.8332 23.5979 34.4003C25.2532 37.2475 27.1683 37.8179 29.0529 37.8179C31.075 37.8179 32.3687 36.9724 33.2091 35.8061C34.0597 34.6346 34.4519 33.132 34.5996 31.166C34.7422 29.1999 34.6964 24.1575 34.6964 24.1575H28.5486Z"
                                fill="white" />
                            <path
                                d="M36.3759 7.87296C33.7171 5.21422 29.3878 5.21422 26.729 7.87296C25.4404 9.16159 24.7324 10.873 24.7324 12.6964C24.7324 14.5198 25.4404 16.2312 26.729 17.5198L31.5525 22.3433L36.3759 17.5198C37.6645 16.2312 38.3725 14.5198 38.3725 12.6964C38.3725 10.873 37.6645 9.16159 36.3759 7.87296ZM31.5983 15.1157C30.0958 15.1157 28.8784 13.8984 28.8784 12.3959C28.8784 10.8933 30.0958 9.67602 31.5983 9.67602C33.1008 9.67602 34.3182 10.8933 34.3182 12.3959C34.3182 13.8984 33.1008 15.1157 31.5983 15.1157Z"
                                fill="url(#paint0_linear_6768_29830)" />
                        </g>
                        <defs>
                            <linearGradient id="paint0_linear_6768_29830" x1="37.9345" y1="4.8424" x2="18.0143" y2="29.3619"
                                gradientUnits="userSpaceOnUse">
                                <stop stopColor="#39EAFF" />
                                <stop offset="1" stopColor="#0068FF" />
                            </linearGradient>
                            <clipPath id="clip0_6768_29830">
                                <rect width="202.869" height="48" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <svg className='close' width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z" fill="#0068FF" />
                        <path d="M28.5486 24.1575C28.5639 25.8485 28.5181 25.2729 28.5181 26.9028C28.5181 30.9266 27.4587 28.7976 26.1853 26.4546C24.1581 22.7212 23.9697 22.385 21.2702 17.7704C19.7371 15.1524 17.3687 14.3578 14.9493 15.0251C11.1649 16.0641 11.44 20.6125 11.44 22.2424C11.44 26.3273 11.5673 37.6295 11.5673 37.6295C11.5673 37.6295 14.5826 37.5836 18.2244 37.5836C18.097 33.4274 18.0919 29.2407 18.0155 26.5208C17.9697 24.7993 18.5605 25.166 18.9171 25.8434C21.2855 30.351 22.1004 31.8332 23.5979 34.4003C25.2532 37.2475 27.1683 37.8179 29.0529 37.8179C31.075 37.8179 32.3687 36.9724 33.2091 35.8061C34.0597 34.6346 34.4519 33.132 34.5996 31.166C34.7422 29.1999 34.6964 24.1575 34.6964 24.1575H28.5486Z" fill="white" />
                        <path d="M36.3759 7.87296C33.7171 5.21422 29.3878 5.21422 26.729 7.87296C25.4404 9.16159 24.7324 10.873 24.7324 12.6964C24.7324 14.5198 25.4404 16.2312 26.729 17.5198L31.5525 22.3433L36.3759 17.5198C37.6645 16.2312 38.3725 14.5198 38.3725 12.6964C38.3725 10.873 37.6645 9.16159 36.3759 7.87296ZM31.5983 15.1157C30.0958 15.1157 28.8784 13.8984 28.8784 12.3959C28.8784 10.8933 30.0958 9.67602 31.5983 9.67602C33.1008 9.67602 34.3182 10.8933 34.3182 12.3959C34.3182 13.8984 33.1008 15.1157 31.5983 15.1157Z" fill="url(#paint0_linear_1_11)" />
                        <defs>
                            <linearGradient id="paint0_linear_1_11" x1="37.9345" y1="4.8424" x2="18.0143" y2="29.3619" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#39EAFF" />
                                <stop offset="1" stopColor="#0068FF" />
                            </linearGradient>
                        </defs>
                    </svg>
                </h2>
                <ul>
                    <li>
                        <Link className="active" to="/userManagement">
                            <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M20.7933 9.52221C21.5833 10.3122 21.5833 11.5931 20.7933 12.3832C20.0033 13.1732 18.7224 13.1732 17.9324 12.3832C17.1423 11.5931 17.1423 10.3122 17.9324 9.52221C18.7224 8.73218 20.0033 8.73218 20.7933 9.52221" stroke="#0068FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M14.2024 5.91236C15.4189 7.12884 15.4189 9.10115 14.2024 10.3176C12.9859 11.5341 11.0136 11.5341 9.79713 10.3176C8.58065 9.10116 8.58065 7.12885 9.79713 5.91236C11.0136 4.69588 12.9859 4.69588 14.2024 5.91236" stroke="#0068FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M6.06871 9.52221C6.85874 10.3122 6.85874 11.5931 6.06871 12.3832C5.27868 13.1732 3.99779 13.1732 3.20776 12.3832C2.41773 11.5931 2.41773 10.3122 3.20776 9.52221C3.99779 8.73218 5.27868 8.73218 6.06871 9.52221" stroke="#0068FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M23.0002 18.9983V17.9023C23.0002 16.5213 21.8812 15.4023 20.5002 15.4023H19.6992" stroke="#0068FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M1 18.9983V17.9023C1 16.5213 2.119 15.4023 3.5 15.4023H4.301" stroke="#0068FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17.3392 18.9994V17.3984C17.3392 15.4654 15.7722 13.8984 13.8392 13.8984H10.1602C8.22716 13.8984 6.66016 15.4654 6.66016 17.3984V18.9994" stroke="#0068FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg></span>User Management
                        </Link>
                    </li>
                    <li>
                        <Link to="/dataManagement">
                            <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7 21H17C18.6569 21 20 19.6569 20 18V8.37167C20 7.57602 19.6839 6.81296 19.1213 6.25035L16.7497 3.87868C16.187 3.31607 15.424 3 14.6283 3H7C5.34315 3 4 4.34315 4 6V18C4 19.6569 5.34315 21 7 21Z" stroke="#9D9EA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M16 17H8" stroke="#9D9EA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M16 13.5H8" stroke="#9D9EA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M11 10H8" stroke="#9D9EA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M20 8.5H16.5C15.3954 8.5 14.5 7.60457 14.5 6.5V3" stroke="#9D9EA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg></span>
                            Data Management
                        </Link>
                    </li>
                    <li>
                        <Link to="/analytics">
                            <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M21 12V12C21 16.971 16.971 21 12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12Z" stroke="#9D9EA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M18.364 18.364L12.293 12.293C12.105 12.105 12 11.851 12 11.586V3" stroke="#9D9EA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12.0703 11.96L19.7903 7.5" stroke="#9D9EA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg></span>
                            Analytics
                        </Link>
                    </li>
                    <li>
                        <Link to="/settings">
                            <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M20.5412 14.5983L20.0795 14.8649C19.1509 15.401 18.5789 16.3917 18.5788 17.464V17.9972C18.5788 19.0694 18.0068 20.0601 17.0782 20.5962C16.1496 21.1323 15.0056 21.1323 14.077 20.5963L13.6152 20.3296C12.6867 19.7935 11.5426 19.7935 10.614 20.3296L10.1522 20.5963C9.22365 21.1323 8.07963 21.1323 7.15107 20.5962C6.22251 20.0601 5.65047 19.0694 5.65043 17.9972V17.464C5.6504 16.3917 5.07837 15.401 4.1498 14.8649L3.68806 14.5983C2.75951 14.0621 2.1875 13.0714 2.1875 11.9992C2.1875 10.9269 2.75951 9.93616 3.68806 9.40003L4.14984 9.13343C5.07841 8.59732 5.65044 7.60657 5.65047 6.53435V6.00113C5.65051 4.92892 6.22255 3.93818 7.15111 3.40208C8.07967 2.86599 9.22369 2.86597 10.1523 3.40205L10.6141 3.66867C11.5426 4.20478 12.6867 4.20478 13.6153 3.66867L14.0771 3.40205C15.0057 2.86597 16.1497 2.86599 17.0782 3.40208C18.0068 3.93818 18.5788 4.92892 18.5789 6.00113V6.53435C18.5789 7.60657 19.1509 8.59732 20.0795 9.13343L20.5413 9.40003C21.4698 9.93617 22.0418 10.927 22.0418 11.9992C22.0418 13.0714 21.4698 14.0622 20.5412 14.5983Z" stroke="#9D9EA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <circle cx="11.9993" cy="12.0013" r="3.00125" stroke="#9D9EA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg></span>Settings
                        </Link>
                    </li>
                    <li>
                        <Link onClick={toggleSidebar} >
                            <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3Z" stroke="#9D9EA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8 12H16" stroke="#9D9EA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M11 15L8 12L11 9" stroke="#9D9EA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg></span>Minimize Menu
                        </Link>
                        <button onClick={toggleSidebar} className="toggle-button" >
                            <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3Z" stroke="#9D9EA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8 12H16" stroke="#9D9EA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M11 15L8 12L11 9" stroke="#9D9EA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg></span>Minimize Menu
                        </button>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default Sidebar